#logo-img {
  max-width: 200px;
}

.card-title {
  color: red;
}

body {
  min-height: 100vh;
  background-color: #f8f8f8;
}
